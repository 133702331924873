import { Link as GatsbyLink } from "gatsby"
import * as React from "react"
import styled from "styled-components"

const style = `
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-family: nunito;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        background-color: black;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }
`

export const Link = styled(props => <GatsbyLink {...props} />)`
    ${style}
`
export const HREF = styled.a`
    ${style}
`
