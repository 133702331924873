import * as React from "react"

export const Theme = {
    colors: {
        primary: "#FDD835",
        light: "#FEE886",
        background: "#F5F5F5",
        paper: "#FFFFFF",
        icon: "#2F3438",
        text: "#2F3438",
        blockQuote: "#EDEDED"
    }
}

export const DarkTheme = {
    colors: {
        primary: "#FDD835",
        light: "#c6a700",
        background: "#25292E",
        paper: "#2F3438",
        icon: "#FEFEFE",
        text: "#FEFEFE",
        blockQuote: "#74838a"
    }
}
