// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-education-mdx": () => import("./../../../src/pages/education.mdx" /* webpackChunkName: "component---src-pages-education-mdx" */),
  "component---src-pages-experience-mdx": () => import("./../../../src/pages/experience.mdx" /* webpackChunkName: "component---src-pages-experience-mdx" */),
  "component---src-pages-future-mdx": () => import("./../../../src/pages/future.mdx" /* webpackChunkName: "component---src-pages-future-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-skills-mdx": () => import("./../../../src/pages/skills.mdx" /* webpackChunkName: "component---src-pages-skills-mdx" */)
}

