import * as React from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"

import { Theme } from "../styles/Theme"
import { CMGCodeIcon } from "./CMGCodeIcon"
import { SocialLinks } from "./SocialLinks"

const Wrapper = styled.footer`
    background-color: ${props => props.theme.colors.primary};
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    pointer-events: auto;
    padding: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`

const Navigation = styled.div`
    display: flex;
    justify-content: center;
`

const Links = styled.div`
    display: flex;
    justify-content: center;
`

const Notice = styled.p`
    font-size: 0.8rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    align-self: center;
    color: ${Theme.colors.text};
`

export const Footer: FunctionComponent = () => {
    return (
        <Wrapper>
            <Navigation>
                <CMGCodeIcon />
            </Navigation>
            <Links>
                <SocialLinks />
            </Links>
            <Notice>This website was designed and created by Campbell Gorman</Notice>
        </Wrapper>
    )
}
