import * as React from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"

import { IconButton } from "../util/Icon"

const Link = styled(IconButton)`
    margin: 2rem;
`

export const SocialLinks: FunctionComponent = () => (
    <>
        <Link icon="email" href="mailto:campbell@cmgcode.com" />
        <Link icon="github" href="https://github.com/CampbellMG" />
        <Link icon="stackOverflow" href="https://stackoverflow.com/users/6907055/campbellmg" />
        <Link icon="linkedin" href="https://www.linkedin.com/in/campbell-gorman/" />
    </>
)
