import React, { FunctionComponent, ReactElement } from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import { DarkTheme, Theme as LightTheme } from "../styles/Theme"
import { useDarkMode } from "../util/Hooks"

type ThemeProps = {
    children: (idDarkMode: boolean, toggleDarkMode: () => void) => ReactElement
}

const GlobalTheme = createGlobalStyle`
  body {
    color: ${props => props.theme.colors.text};
    font-family: nunito;
    margin: 0;
  }

  a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-family: nunito;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      background-color: black;
      transition: transform 0.3s;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  blockquote {
    padding: 1rem;
    font-style: italic;
    border-left: 8px solid ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.blockQuote};
  }

  table {
    width: 100%;
  }

  td {
    width: 50%;
  }

  td ul {
    margin: auto;
    width: 25%
  }

  p {
    font-size: 1.1rem;
  }
  
  .paper p {
    text-align: center;
  }
`

export const Theme: FunctionComponent<ThemeProps> = props => {
    const [mode, toggleMode, componentMounted] = useDarkMode()

    const isDarkMode = mode === "dark"
    const theme = isDarkMode ? DarkTheme : LightTheme

    if (!componentMounted) {
        return <div />
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalTheme />
            {props.children(isDarkMode, toggleMode)}
        </ThemeProvider>
    )
}
