import * as React from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"

import { Header } from "./Header"
import "../styles/fonts.css"
import { Footer } from "./Footer"
import { Theme } from "./Theme"

const Wrapper = styled.div`
    background-color: ${props => props.theme.colors.background};
    padding-top: 4rem;
    padding-bottom: 20rem;
    min-height: calc(100vh - 24rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const Content = styled.main`
    background-color: ${props => props.theme.colors.paper};
    max-width: 60vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding: 2vw;
    border-radius: 20px;
    @media only screen and (max-width: 768px) {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
`

export const Layout: FunctionComponent = props => (
    <Theme>
        {(isDarkMode, toggleMode) => (
            <>
                <Wrapper>
                    <Header visible onToggleDarkMode={toggleMode} isDarkMode={isDarkMode} />
                    <Content>{props.children}</Content>
                    <Footer />
                </Wrapper>
            </>
        )}
    </Theme>
)

export default Layout
