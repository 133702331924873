import * as React from "react"
import { FunctionComponent } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"

import { Link as HyperLink, HREF } from "../components/Link"
import { Icon, IconButton } from "../util/Icon"
import { CMGCodeIcon } from "./CMGCodeIcon"

type HeaderProps = {
    visible: boolean
    onToggleDarkMode: () => void
    isDarkMode: boolean
}

type WrapperProps = {
    $visible: boolean
}

const Wrapper = styled(animated.header)<WrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${props => (props.$visible ? props.theme.colors.primary : "transparent")};
    z-index: 99;
    padding: 0.5rem 2rem;
    pointer-events: auto;
`

const ContentWrapper = styled(animated.div)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
`

const HomeLink = styled.div`
    flex: 1;
`

const Menu = styled.div`
    display: flex;
    flex-direction: row;
`

const Link = styled(HyperLink)`
    margin-right: 2rem;
    color: black;

    :visited {
        color: black;
    }
`

const DarkModeToggle = styled.a`
    margin-left: 2rem;
    transition: all 0.2s;
    cursor: pointer;

    :hover {
        transform: scale(1.2);
    }
`

export const Header: FunctionComponent<HeaderProps> = props => {
    const headerAnimation = useSpring({
        transform: props.visible ? "translateY(0vh)" : "translateY(-20vh)",
        config: {
            duration: 300
        }
    })

    return (
        <Wrapper $visible={props.visible}>
            <ContentWrapper style={headerAnimation}>
                <HomeLink>
                    <CMGCodeIcon />
                </HomeLink>
                <Menu>
                    <Link to="/projects">Projects</Link>
                    <Link to="/skills">Skills</Link>
                    <Link to="/experience">Experience</Link>
                    <Link to="/education">Education</Link>
                    <Link to="/future">Future</Link>
                    <HREF href="/resume.pdf">Resume</HREF>
                    <DarkModeToggle onClick={props.onToggleDarkMode}>
                        <Icon icon={props.isDarkMode ? "moon" : "sun"} size={24} />
                    </DarkModeToggle>
                </Menu>
            </ContentWrapper>
        </Wrapper>
    )
}
