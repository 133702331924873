import { MutableRefObject, useEffect, useRef, useState } from "react"

export const useOnScreen = (ref: MutableRefObject<HTMLElement>, rootMargin = "0px"): boolean => {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting)
            },
            { rootMargin }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.unobserve(ref.current)
        }
    }, [])

    return isIntersecting
}

export type Mode = "light" | "dark"
export const useDarkMode = (): [Mode, () => void, boolean] => {
    const [theme, setTheme] = useState<Mode>("light")
    const [componentMounted, setComponentMounted] = useState(false)
    const setMode = (mode: Mode) => {
        window.localStorage.setItem("theme", mode)
        setTheme(mode)
    }

    const toggleTheme = () => {
        if (theme === "light") {
            setMode("dark")
        } else {
            setMode("light")
        }
    }

    useEffect(() => {
        const localTheme = window.localStorage.getItem("theme") as Mode | undefined
        window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches && !localTheme
            ? setMode("dark")
            : localTheme
            ? setTheme(localTheme)
            : setMode("light")
        setComponentMounted(true)
    }, [])

    return [theme, toggleTheme, componentMounted]
}

export const usePrevious = <T,>(value: T): T | undefined => {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}
