import { Link } from "gatsby"
import * as React from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"

import { Theme } from "../styles/Theme"

const StyledLink = styled(props => <Link {...props} />)`
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-family: mighty-mtn;
    font-size: 3rem;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        background-color: black;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }

    :visited {
        color: black;
    }
`

export const CMGCodeIcon: FunctionComponent = props => (
    <StyledLink to="/" {...props}>
        CMGCode
    </StyledLink>
)
